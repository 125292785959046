import topic from '../topic';
import { getNoCatch, postNoCatch } from '@api/request';

export const podrAPI = {
  getPodrs: topic.podrTopic + '/get_podrs',
  getPodrList: topic.podrTopic + '/get_podr_list',
  getPodrById: topic.podrTopic + '/get_podr_by_id',
  addPodr: topic.podrTopic + '/add_podr',
  editPodr: topic.podrTopic + '/edit_podr',
  addPdrToProd: topic.podrTopic + '/add_podr_to_prod',
  deletePodrByIds: topic.podrTopic + '/delete_podr_by_ids',
  exportPodrPdfById: topic.podrTopic + '/pdf/export_podr_pdf_by_id',
  getPodrBySconId: topic.podrTopic + '/get_podr_by_scon_id',
  getPodrPartByPodrId: topic.podrTopic + '/get_podr_part_by_podr_id',
  get_podr_statistics: topic.podrTopic + '/get_podr_statistics',
  editRemark: params => getNoCatch(`${topic.podrTopic}/editRemark`, params),
  editPodrStat: params => getNoCatch(`${topic.podrTopic}/editPodrStat`, params),
  add_podr_statistics: params => postNoCatch(`${topic.podrTopic}/add_podr_statistics`, params),
  edit_podr_statistics: params => postNoCatch(`${topic.podrTopic}/edit_podr_statistics`, params),
  exportExcel: params => getNoCatch(`${topic.podrTopic}/export_podr_statistics`, params),
  get_procurement_contract_statistics: params => getNoCatch(`${topic.podrTopic}/get_procurement_contract_statistics`, params)
};

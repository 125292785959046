<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_button_group vg_mtb_16 flexHV">
        <el-button type="danger" size="small" @click="doDelete()" :disabled="!btn.delete"><i class="el-icon-delete"></i> 删除</el-button>
        <el-date-picker
          class="vg_ml_8 vg_mr_8 topDateRangeSearch"
          size="small"
          v-model="dateRange"
          type="daterange"
          value-format="timestamp"
          range-separator="至"
          end-placeholder="要求交货结束日期"
          start-placeholder="要求交货开始日期"
        />
        <el-date-picker
          v-model="dateRange2"
          class="vg_ml_8 vg_mr_8 topDateRangeSearch"
          end-placeholder="合同结束日期"
          range-separator="至"
          size="small"
          start-placeholder="合同开始日期"
          type="daterange"
          value-format="timestamp"
        />
        <el-button type="primary" size="small" icon="el-icon-search" @click="getPodrsNow()"> 查询</el-button>
        <el-button type="info" size="small" class="vd_export" icon="el-icon-refresh-right" @click="buttonRefresh()">刷新</el-button>
        <el-button class="vd_export" size="small" type="success" @click="generateCollectionInformation()">生成托收信息</el-button>
        <div style="margin-left: auto">
          <div style="display: flex">
            <div style="width: 250px">全部页货款金额累计:{{ totalForm.sum_podr_prtotal }}</div>
          </div>
          <div style="display: flex">
            <div style="width: 250px">全部页费用金额累计:{{ totalForm.sum_podr_fetotal }}</div>
          </div>
          <div style="display: flex">
            <div style="width: 250px">全部页合同金额累计:{{ totalForm.sum_podr_total }}</div>
          </div>
        </div>
      </div>
      <search-table
        ref="multiTable"
        :data="tableData"
        :tableRowKey="`podr_id`"
        :columns="tableProperties"
        v-loading="loadFlag"
        :totalPage="totalPage"
        :page-size="50"
        :show-summary="true"
        :summariesColumns="['podr_total', 'podr_prtotal', 'podr_fetotal']"
        @select="selectRows"
        @selection-change="handleSelectionChange"
        @select-all="selectRows"
        @row-dblclick="dbClickJp"
        @getTableData="getPodrsNow"
      >
        <template v-slot:status="scope">
          <div style="text-align: center">
            <el-tag :type="helper.getStatusName(scope.row.status).type" size="mini">
              {{ helper.getStatusName(scope.row.status).name }}
            </el-tag>
          </div>
        </template>
        <template v-slot:podr_remark="scope">
          <el-input v-model="tableData[scope.$index].podr_remark" size="small" maxlength="50" clearable>
            <template #append>
              <el-link type="primary" size="small" @click="editRemark(scope.$index)" class="vg_cursor">保存</el-link>
            </template>
          </el-input>
        </template>
      </search-table>
    </el-card>
  </div>
</template>

<script>
import { getNoCatch, postNoCatch } from '@api/request';
import { podrAPI } from '@api/modules/podr';
import { deptAPI } from '@api/modules/department';
import pubPagination from '@/components/common/pubPagination';
import { getDateNoTime } from '@assets/js/dateUtils';
import SearchTable from '@/components/table/SearchTableV2.vue';
import { informAPI } from '@api/modules/inform';
import { cloneDeep } from 'lodash';
import { setTime } from '@api/public';
import { arrayToString, getArrayIds } from '@assets/js/arrayUtils';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'PodrList',
  components: {
    SearchTable,
    pubPagination
  },
  data() {
    return {
      dateRange: [],
      dateRange2: [],
      tableProperties: [
        { prop: 'selection', type: 'selection', labelWidth: '48px' },
        { prop: 'index', type: 'index', labelWidth: '50px', label: '序号' },
        // {
        //   prop: 'podr_stat',
        //   itemType: 'select',
        //   label: '是否下单',
        //   options: [
        //     { value: 0, label: '未下单' },
        //     { value: 1, label: '已下单' }
        //   ],
        //   needOtherColumn: true,
        //   formatter: val => this.formaPodrStat(val),
        //   sortable: false
        // },
        // {
        //   prop: 'email_status',
        //   itemType: 'select',
        //   label: '邮件发送状态',
        //   options: [
        //     { value: 0, label: '未发送' },
        //     { value: 1, label: '已发送' }
        //   ],
        //   formatter: val => (val === 0 ? '未发送' : val === 1 ? '已发送' : '暂无'),
        //   sortable: false
        // },
        { prop: 'scon_no', itemType: 'input', label: '销售合同号', sortable: false },
        { prop: 'podr_no', itemType: 'input', label: '采购合同号', sortable: false },
        { prop: 'contract_nos', itemType: 'input', input: false, label: '托收合同号', sortable: false, labelWidth: '100px' },
        { prop: 'podr_note', itemType: 'input', label: '合同号备注', sortable: false },
        { prop: 'supp_abbr', itemType: 'input', label: '供应商简称', sortable: true },
        {
          prop: 'custAbbrList',
          itemType: 'select',
          options: [],
          multiple: true,
          label: '客户简称',
          sortable: true,
          needOtherColumn: true,
          collapseTags: true,
          formatter: row => row.cust_abbr,
          labelWidth: 220
        },
        {
          prop: 'custBAbbrList',
          itemType: 'select',
          options: [],
          multiple: true,
          label: '最终客户',
          sortable: false,
          needOtherColumn: true,
          collapseTags: true,
          formatter: row => row.cust_babbr,
          labelWidth: 220
        },
        { prop: 'scon_cust_no', itemType: 'input', label: '客户订单号', sortable: false },
        {
          prop: 'podr_cndate',
          itemType: 'date',
          label: '合同日期',
          sortable: true,
          formatter: val => getDateNoTime(val, true),
          labelWidth: 160
        },
        { prop: 'podr_pedate', itemType: 'date', label: '要求交货日期', sortable: true, formatter: val => getDateNoTime(val, true) },
        { prop: 'cptt_name', itemType: 'input', label: '公司抬头', labelWidth: 120 },
        {
          prop: 'podr_payway',
          itemType: 'select',
          label: '结算方式',
          options: [
            { value: 1, label: '我司结算' },
            { value: 2, label: '工厂结算' }
          ],
          labelWidth: 120,
          formatter: val => (val === 1 ? '我司结算' : val === 2 ? '工厂结算' : '暂无')
        },
        { prop: 'podr_stff_name', itemType: 'input', label: '采购经办人', sortable: false },
        {
          prop: 'podr_dept_id',
          itemType: 'select',
          label: '采购部门',
          options: [],
          sortable: false,
          needOtherColumn: true,
          formatter: val => val.podr_dept_name
        },
        { prop: 'cust_stff_name', itemType: 'input', label: '销售经办人', sortable: false },
        {
          prop: 'cust_dept_id',
          itemType: 'select',
          label: '销售部门',
          options: [],
          needOtherColumn: true,
          formatter: val => val.cust_dept_name,
          sortable: false
        },
        { prop: 'stff_name', label: '录入人', itemType: 'input', labelWidth: '100px', sortable: false },
        { prop: 'create_time', label: '录入时间', itemType: 'date', labelWidth: '120px', formatter: val => getDateNoTime(val, true) },
        { prop: 'podc_num', label: '变更次数', itemType: 'input', input: false, sortable: false },
        { prop: 'podr_total', label: '合同金额', itemType: 'input', input: false, sortable: true, align: 'right' },
        { prop: 'podr_prtotal', label: '货款金额', itemType: 'input', input: false, sortable: true, align: 'right' },
        { prop: 'podr_fetotal', label: '费用金额', itemType: 'input', input: false, sortable: true, align: 'right' },
        { prop: 'podr_remark', itemType: 'input', label: '备注', labelWidth: '180px', input: false, sortable: false },
        {
          prop: 'status',
          label: '单据状态',
          itemType: 'select',
          fixed: 'right',
          options: [
            { value: 0, label: '草拟' },
            { value: 1, label: '在批' },
            { value: 2, label: '生效' }
          ]
        }
      ],
      loadFlag: true,
      custList: [],
      currentPage: 1,
      totalPage: 0,
      tableData: [],
      multiSelection: [],
      btn: {},
      stffUserList: [],
      ShipmentStatusList: [
        { id: 0, label: '未出运' },
        { id: 1, label: '已出运' }
      ],
      custDportList: [],
      custPaywayList: [],
      suppList: [],
      deptList: [],
      totalForm: {}
    };
  },
  created() {
    this.initData();
  },
  mounted() {
    let { scon_no, podr_no, prod_no } = this.$route.query;
    if (scon_no) this.$refs.multiTable.searchForm.scon_no = scon_no;
    if (podr_no) this.$refs.multiTable.searchForm.podr_no = podr_no;
    if (prod_no) this.$refs.multiTable.searchForm.prod_no = prod_no;
    this.getPodrs();
  },
  watch: {
    $route(to, from) {
      if (from.path === '/podr_add' || from.path === '/podr_edit') {
        this.getPodrs();
        this.initData();
      }
    }
  },
  methods: {
    initData() {
      this.getDept();
    },
    // 保存主表备注
    editRemark(index) {
      let { podr_id, podr_remark } = this.tableData[index];
      this.loadFlag = true;
      podrAPI.editRemark({ podr_id: podr_id, podr_remark: podr_remark }).then(({ data }) => {
        this.$message.success('保存成功!');
        setTimeout(() => {
          this.loadFlag = false;
        }, 500);
      });
    },
    // 获取公司抬头信息
    getPodrs() {
      let searchForm = cloneDeep(this.$refs.multiTable.searchForm);
      setTime(searchForm, 'startTime', 'endTime', this.dateRange);
      setTime(searchForm, 'startTime2', 'endTime2', this.dateRange2);
      searchForm.custAbbrList = searchForm.custAbbrList?.toString();
      arrayToString(searchForm, 'custAbbrList');
      arrayToString(searchForm, 'custBAbbrList');
      getNoCatch(podrAPI.getPodrs, searchForm).then(({ data }) => {
        this.tableData = data.list;
        this.totalPage = data.total;
        for (const key in data) {
          if (key.includes('sum_')) this.totalForm[key] = data[key];
          if (key === 'custAbbrList') this.tableProperties.find(x => x.label === '客户简称').options = data[key].filter(x => x);
          if (key === 'custBAbbrList') this.tableProperties.find(x => x.label === '最终客户').options = data[key];
        }
        this.btn = data.btn;
        setTimeout(() => {
          this.loadFlag = false;
        }, 500);
      });
    },
    //获取部门信息
    getDept() {
      getNoCatch(deptAPI.getAllDeptsV1).then(({ data }) => {
        let arr = Array.from(data, ({ dept_name, dept_id }) => {
          return { value: dept_id, label: dept_name };
        });
        this.tableProperties[14].options = arr;
        this.tableProperties[16].options = arr;
      });
    },
    // 查询方法
    getPodrsNow() {
      this.loadFlag = true;
      this.getPodrs();
    },
    // 刷新
    buttonRefresh() {
      this.loadFlag = true;
      this.dateRange = [];
      this.dateRange2 = [];
      this.$refs.multiTable.resetFields();
      this.getPodrs();
    },
    generateCollectionInformation() {
      // let searchForm = cloneDeep(this.$refs.multiTable.searchForm);
      // setTime(searchForm, 'startTime', 'endTime', this.dateRange);
      if (this.multiSelection.length === 0) return this.$message.warning('请选择数据!');
      informAPI.add_informProd({ podr_ids: getArrayIds(this.multiSelection, 'podr_id', true) }).then(({ data }) => {
        this.jump('/inform_edit', {
          key: UrlEncode.encode(
            JSON.stringify({
              perm_id: 176,
              form_id: data.form_id
            })
          )
        });
      });
    },
    // 分页查询
    changePageSearch(val) {
      this.loadFlag = true;
      this.currentPage = val;
      this.getPodrs();
    },
    // 下单状态返回值
    formaPodrStat(val) {
      if (val.podr_stat === 0) {
        return '未下单';
      } else if (val.podr_stat === 1) {
        return '已下单';
      }
    },
    // 多选获取公司抬头信息
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    selectRows(selection) {
      this.btn.delete = this.helper.isDelete(selection);
    },
    // 新增
    addRow() {
      const permId = this.$route.query.perm_id;
      this.jump(`/podr_add?perm_id=${permId}`);
    },
    doDelete() {
      if (this.multiSelection.length === 0) {
        this.$message({ type: 'warning', message: '请至少选择一条数据！' });
      } else {
        this.mBox();
      }
    },
    //删除提示
    mBox() {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.delRow();
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
          this.$refs.multiTable.clearSelection();
        });
    },
    // 删除
    delRow() {
      const ids = [];
      this.multiSelection.map(item => {
        ids.push(item.podr_id);
      });
      postNoCatch(podrAPI.deletePodrByIds, { podr_id_list: ids }).then(({ data }) => {
        this.$message.success('成功!');
        if (ids.length === this.tableData.length) {
          if (this.currentPage > 1) {
            this.currentPage = this.currentPage - 1;
            this.$refs.pubPagination.currentPage = this.currentPage;
          }
        }
        this.getPodrsNow();
      });
    },
    // 双击row跳转到详情编辑
    dbClickJp(row) {
      const permId = this.$route.query.perm_id;
      this.jump('/podr_edit', {
        key: UrlEncode.encode(
          JSON.stringify({
            perm_id: permId,
            form_id: row.podr_id
          })
        )
      });
    }
  }
};
</script>

<style scoped></style>
